<template>
  <div>
    <v-layout
      v-show="!isCustomerSelected && !isModeView"
      :column="isInSidebarDialog"
      align="space-between"
      class="sheet"
      :style="isInSidebarDialog ? 'max-width: 500px; margin-top: 10px' : ''"
    >
      <v-flex
        padded
        :class="isInSidebarDialog ? 'xs12' : 'xs6'"
        :style="isInSidebarDialog ? 'margin-left: 30px' : ''"
      >
        <v-form ref="form" :lazy-validation="true" autocomplete="off">
          <v-layout row wrap>
            <v-flex xs12>
              <CRInput
                id="quote-form-first-name"
                v-model="value.firstName"
                :name="uuidv4()"
                label="First Name"
                required
                placeholder="e.g. Jane"
                debounce="0"
                browser-autocomplete="chrome-off"
                autocomplete="off"
                :rules="
                  (suggestedCustomers.length === 0 ||
                    sameCustomerDifferentEmail) &&
                  hasCustomerFieldInputs
                    ? customerFirstNameRules
                    : []
                "
                @input="(_value) => searchCustomerDetail('firstName', _value)"
              />
            </v-flex>
            <v-flex xs12>
              <CRInput
                id="quote-form-customer-last-name"
                v-model="value.lastName"
                :name="uuidv4()"
                label="Last Name"
                placeholder="e.g. Doe"
                browser-autocomplete="chrome-off"
                autocomplete="off"
                :rules="
                  (suggestedCustomers.length === 0 ||
                    sameCustomerDifferentEmail) &&
                  hasCustomerFieldInputs
                    ? customerLastNameRules
                    : []
                "
                @input="(_value) => searchCustomerDetail('lastName', _value)"
              />
            </v-flex>
            <v-flex xs12>
              <CRInput
                id="quote-form-customer-phone"
                ref="quote-form-customer-phone"
                v-model="value.phone"
                v-mask="['(###) ###-####', '+## ## #### ####']"
                :name="uuidv4()"
                label="Phone"
                placeholder="e.g. (555) 867-5309"
                browser-autocomplete="chrome-off"
                :rules="
                  (suggestedCustomers.length === 0 ||
                    sameCustomerDifferentEmail) &&
                  hasCustomerFieldInputs
                    ? customerPhoneRules
                    : []
                "
                @input="
                  (_value) =>
                    searchCustomerDetail('phone', _value.replace(/[^0-9]/g, ''))
                "
              />
            </v-flex>
            <v-flex xs12>
              <CRInput
                id="quote-form-customer-email"
                v-model="value.email"
                :name="uuidv4()"
                required
                label="Email"
                placeholder="e.g. janedoe@domainexample.com"
                browser-autocomplete="chrome-off"
                :rules="customerEmailRules"
                @input="(_value) => searchCustomerDetail('email', _value)"
              />
            </v-flex>
          </v-layout>
          <div>
            <CRInput
              id="quote-form-organization"
              v-model="value.organization"
              :name="uuidv4()"
              label="Organization"
              placeholder="e.g. Spirit Airlines"
              browser-autocomplete="chrome-off"
            />
            <CRInput
              id="quote-form-bookings-per-year"
              v-model="value.bookings"
              :name="uuidv4()"
              label="Bookings per Year"
              placeholder="e.g. 100"
              browser-autocomplete="chrome-off"
              :rules="bookingYearRules"
            />
            <IndustrySelector
              :key="initialIndustryId"
              ref="industrySelector"
              v-model="value.industryId"
              :mode="mode"
              :initial-industry="initialIndustryId"
              :industry-required="industryRequired"
              @is-other="(_value) => (shouldShowIndustryDescription = _value)"
            />
            <CRInput
              v-if="shouldShowIndustryDescription"
              id="quote-form-industry-description"
              v-model="value.industryDescription"
              :name="uuidv4()"
              label="Industry Description"
              placeholder="Industry Description"
              browser-autocomplete="chrome-off"
              :rules="
                value.industry &&
                value.industry.isOther &&
                hasCustomerFieldInputs
                  ? industryDescriptionRules
                  : []
              "
            />
          </div>
          <template v-if="isEditingCustomer">
            <v-btn
              id="quote-form-customer-save-button"
              class="btn-primaryaction"
              :disabled="customerLoading || customerEmailTaken"
              @click="saveAndProceed"
            >
              Save
            </v-btn>
            <v-btn
              id="quote-form-customer-cancel-button"
              class="btn-secondaryaction"
              @click="cancelEditingCustomer"
            >
              Cancel
            </v-btn>
          </template>
        </v-form>
      </v-flex>
      <v-flex
        v-if="
          (suggestedCustomers.length > 0 &&
            !createNewSelected &&
            !customerLoading &&
            !isEditingCustomer) ||
          (isEditingCustomer && !customerLoading && customerEmailTaken)
        "
        padded
        class="customer-suggestions"
        :class="isInSidebarDialog ? 'xs12' : 'xs6'"
      >
        <div
          v-for="(suggestedCustomer,
          suggestedCustomerIndex) in suggestedCustomers"
          :key="`suggestedCustomer-${suggestedCustomerIndex}`"
          class="customer-suggestion is-clearfix"
        >
          <div class="customer-suggestion-row">
            <v-btn
              :id="`quote-form-select-customer-button-${suggestedCustomerIndex}`"
              class="primary left"
              @click="() => selectCustomerFromList(suggestedCustomer)"
            >
              Select
            </v-btn>
            <div>
              <h6>
                {{ suggestedCustomer.firstName }}
                {{ suggestedCustomer.lastName }}
              </h6>
              <div>{{ suggestedCustomer.phone | phoneFormatFilter }}</div>
              <div>{{ suggestedCustomer.email }}</div>
              <div
                v-if="suggestedCustomer.isBanned"
                :style="`color: ${$cr.theme.error}`"
              >
                Banned
              </div>
              <div v-if="suggestedCustomer.organization">
                {{ suggestedCustomer.organization }}
              </div>
              <div v-if="suggestedCustomer.bookings">
                {{ suggestedCustomer.bookings }} bookings per year
              </div>
            </div>
          </div>
        </div>
      </v-flex>

      <QuoteFormCustomerAccountForm
        v-if="createNewSelected"
        @on-submit="onNewCustomerAccount"
        @on-cancel="createNewSelected = false"
      />

      <v-flex v-if="customerLoading">
        <v-layout
          column
          justify-space-around
          style="background-color: white; height: 300px"
        >
          <v-progress-circular
            style="margin: 0 auto"
            :size="70"
            indeterminate
            color="primary"
          />
        </v-layout>
      </v-flex>
      <v-flex
        v-else-if="
          suggestedCustomers.length === 0 &&
          hasCustomerFieldInputs &&
          !createNewSelected &&
          isValidSearch &&
          !isEditingCustomer
        "
        justify-center
        layout
        column
        align-center
        xs6
      >
        <CRIcon :width="40" :height="40">my_account</CRIcon>
        <h3>This looks like a new customer</h3>
        <h3>Create a new customer to save for future quotes</h3>
        <v-btn
          id="quote-form-add-new-customer-button"
          class="btn-primaryaction"
          :disabled="submitting"
          :loading="submitting"
          @click="submitNewCustomerForm"
        >
          Add New Customer
        </v-btn>
      </v-flex>
    </v-layout>

    <section
      v-show="(isCustomerSelected || isModeView) && !isNewDisplay"
      class="sheet"
    >
      <div class="customer-info">
        <v-container>
          <v-layout :column="isInSidebarDialog" padded wrap>
            <v-flex>
              <v-layout :column="isInSidebarDialog" padded justify-space-around>
                <v-flex xs4>
                  <div class="customer-name">
                    {{ userTitle }}
                    <h3>
                      {{ value.firstName | nullInfoFilter }}
                      {{ value.lastName | nullInfoFilter }}
                      {{ value.customerAccountName }}
                    </h3>
                  </div>
                </v-flex>
                <v-flex>
                  <div class="customer-email">
                    Email
                    <h3
                      v-if="value.email && !value.email.includes('tripContact')"
                      id="user-selector-label-customer-email"
                    >
                      {{ value.email | nullInfoFilter }}
                    </h3>
                  </div>
                </v-flex>
                <v-flex>
                  <div
                    id="user-selector-label-phone-email"
                    class="customer-phone"
                  >
                    Phone
                    <h3>
                      {{ value.phone | phoneFormatFilter | nullInfoFilter }}
                    </h3>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex>
              <v-layout :column="isInSidebarDialog" padded justify-space-around>
                <v-flex>
                  <div class="customer-account">
                    Organization
                    <h3 id="user-selector-label-organization">
                      {{
                        value && value.organization ? value.organization : '---'
                      }}
                    </h3>
                  </div>
                </v-flex>
                <v-flex>
                  <div class="customer-account">
                    <span
                      v-if="!value.bookings"
                      :style="`color: ${$cr.theme.error}`"
                    >
                      Bookings Per Year (Required)
                    </span>
                    <span v-if="value.bookings">Bookings Per Year</span>
                    <h3 id="user-selector-label-bookings">
                      {{ value && value.bookings ? value.bookings : '---' }}
                    </h3>
                  </div>
                </v-flex>
                <v-flex>
                  <div
                    v-if="!isModeView"
                    class="customer-header-actions"
                    :style="
                      isInSidebarDialog
                        ? 'display: flex; justify-content: center;'
                        : ''
                    "
                  >
                    <div v-if="$cr.breakpoint.lgAndUp">
                      <v-btn
                        class="btn-secondaryaction"
                        @click="resetCustomerSelection"
                      >
                        Remove
                      </v-btn>
                      <v-btn
                        :id="`${id}-user-selector-button-edit-customer-selection`"
                        class="btn-primaryaction"
                        @click="editCustomerSelection"
                      >
                        Edit
                      </v-btn>
                      <v-btn
                        v-if="!isModeView && pastQuotesButton === true"
                        :id="`${id}-user-selector-button-toggle-show-past-quotes`"
                        :class="
                          !showPastQuotes
                            ? 'btn-secondaryaction'
                            : 'btn-primaryaction'
                        "
                        @click="toggleShowPastQuotes"
                      >
                        {{ !showPastQuotes ? 'Past Quotes' : 'Back to Quote' }}
                      </v-btn>
                    </div>
                    <div v-else style="text-align: right">
                      <v-btn
                        v-if="!isInSidebarDialog"
                        :id="`${id}-user-selector-button-reset-customer-selection`"
                        class="remove-icon"
                        icon
                        @click="resetCustomerSelection"
                      >
                        <CRIcon view-box="0 0 24 24" color="primary">
                          close
                        </CRIcon>
                      </v-btn>
                      <v-btn
                        :id="`${id}-user-selector-button-edit-customer-selection-view`"
                        icon
                        style="margin: auto 0px"
                        @click="editCustomerSelection"
                      >
                        <CRIcon view-box="0 0 24 24" color="primary">
                          create
                        </CRIcon>
                      </v-btn>
                      <v-btn
                        v-if="!isModeView && pastQuotesButton === true"
                        :id="`${id}-user-selector-button-toggle-show-past-quotes-view`"
                        :class="
                          !showPastQuotes
                            ? 'btn-secondaryaction'
                            : 'btn-primaryaction'
                        "
                        @click="toggleShowPastQuotes"
                      >
                        {{ !showPastQuotes ? 'Past Quotes' : 'Back to Quote' }}
                      </v-btn>
                    </div>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
    </section>

    <section v-show="(isCustomerSelected || isModeView) && isNewDisplay">
      <v-layout
        row
        wrap
        :class="`customer-select-info ${value.isBanned ? 'banned-red' : ''}`"
        align-start
      >
        <!-- Left Panel Info -->
        <v-flex>
          <v-layout row justify-start align-center class="mx-2">
            <CRIcon color="borderGray" width="40" height="40" margin="0 20px">
              my_account
            </CRIcon>
            <div>
              <v-layout row justify-start align-center>
                <div class="d-flex align-center">
                  <b>
                    {{ value.firstName | nullInfoFilter }}
                    {{ value.lastName | nullInfoFilter }}
                    {{
                      value && value.organization
                        ? `(${value.organization})`
                        : ''
                    }}
                  </b>
                </div>
                <CRIcon
                  v-if="!isModeView && value.customerId"
                  :id="`${id}-user-selector-button-edit-customer-selection`"
                  view-box="0 0 24 24"
                  width="20"
                  height="20"
                  class="margin-l-2"
                  color="primary"
                  @click.native="editCustomerSelection"
                >
                  create
                </CRIcon>
                <CRIcon
                  v-if="!isModeView && $cr.breakpoint.mdAndDown"
                  view-box="0 0 24 24"
                  class="margin-l-1"
                  color="primary"
                  @click.native="resetCustomerSelection"
                >
                  close
                </CRIcon>
              </v-layout>

              <p>
                {{ value.email | nullInfoFilter }}
              </p>
              <div class="d-flex align-center justify-start w-auto">
                <p v-if="value.phone">
                  {{ value.phone | phoneFormatFilter | nullInfoFilter }}
                </p>
                <div
                  v-if="value.quoteFollowUpNotificationSetting"
                  :class="`margin-l-1 sms-opt-out-chip sms-opt-out-chip--${isQuoteFollowUpNotificationSettingEnabled}`"
                >
                  <p style="line-height: 100%">{{ quoteFollowUpNotificationSettingLabel }}</p>
                </div>
              </div>
              <br />
              <v-flex xs12 style="min-height: 25px">
                <v-flex xs4>
                  <v-btn
                    v-if="!customerNotesExist"
                    :id="`user-selector-button-add-customer-notes`"
                    color="primary"
                    style="margin: 0 0 0 -6px"
                    flat
                    small
                    @click="editCustomerNote(value)"
                  >
                    Add Contact Note
                  </v-btn>
                </v-flex>
                <v-flex v-if="customerNotesExist" xs12>
                  <p>
                    <b>Contact Note</b>
                    <span class="edit-button" @click="editCustomerNote(value)">
                      Edit
                    </span>
                  </p>
                  <div style="overflow-wrap: break-word">
                    <p v-html="customerNotesForDisplay" />
                  </div>
                </v-flex>
              </v-flex>
              <p v-if="value.isBanned" :style="`color: ${$cr.theme.error}`">
                This customer has been banned. Do not quote.
              </p>
            </div>
          </v-layout>
        </v-flex>
        <v-flex
          v-if="$cr.breakpoint.smAndDown"
          xs12
          style="padding: 0 24px; margin-top: 12px"
        >
          <v-divider />
        </v-flex>
        <!-- Right Panel -->
        <v-flex>
          <v-layout row align-center class="right-panel">
            <!-- Buttons -->
            <v-layout
              v-if="!isModeView && $cr.breakpoint.lgAndUp"
              col
              wrap
              justify-start
            >
              <v-flex>
                <div class="d-flex justify-end">
                  <v-btn color="primary" @click="resetCustomerSelection">
                    Change Customer
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>
            <span v-if="$cr.breakpoint.mdAndUp" class="lg-divider" />
            <div
              v-if="$cr.breakpoint.smAndDown"
              class="min-w-40 margin-l-3 margin-r-3"
            />
            <v-layout
              row
              justify-start
              shrink
              wrap
              class="margin-l-6"
              style="width: 500px"
            >
              <!-- Last Quoted By and Broker Info-->
              <v-flex grow>
                <div v-if="customerLastQuoted">
                  <v-layout row align-center>
                    <div class="customer-text-light">Last Quoted:</div>
                    <p class="margin-l-1">
                      {{ customerLastQuoted.createdOn.split('T')[0] }}
                    </p>
                  </v-layout>
                  <v-layout row align-center>
                    <div class="customer-text-light">by</div>
                    <p class="margin-l-1">
                      {{ customerLastQuoted.sentByName }}
                    </p>
                    <a class="margin-l-1" @click="toggleShowLastQuote">
                      {{ customerLastQuoted.quoteId }}
                    </a>
                  </v-layout>
                </div>
                <p>
                  <span
                    v-if="!value.bookings"
                    :style="`color: ${$cr.theme.error}; font-weight: 500`"
                  >
                    Bookings Per Year (Required):
                  </span>
                  <span v-else class="customer-text-light">
                    Bookings Per Year:
                  </span>
                  {{ value && value.bookings ? value.bookings : '---' }}
                </p>
                <p>
                  <span
                    v-if="!value.industry"
                    :style="`color: ${$cr.theme.error}; font-weight: 500`"
                  >
                    Industry (Required):
                  </span>
                  <span
                    v-else-if="
                      value.industry.subIndustryIds &&
                      !(value.industry.subIndustryIds.length === 0)
                    "
                    :style="`color: ${$cr.theme.error}; font-weight: 500`"
                  >
                    Sub-Industry (Required):
                  </span>
                  <span v-else class="customer-text-light">Industry:</span>
                  {{ value && value.industry ? industryLabel : '---' }}
                </p>
                <p>
                  <span
                    v-if="
                      value.industry &&
                      value.industry.isOther &&
                      !value.industryDescription
                    "
                    :style="`color: ${$cr.theme.error}; font-weight: 500`"
                  >
                    Industry Description (Required):
                  </span>
                  <span
                    v-else-if="
                      value.industry &&
                      value.industry.isOther &&
                      value.industryDescription
                    "
                    class="customer-text-light"
                  >
                    Industry Description:
                  </span>
                  {{ shouldShowIndustryDescription ? industryDescription : '' }}
                </p>
                <p v-if="customerInvitationStatus">
                  <span class="customer-text-light">CharterUP:</span>
                  <span
                    :class="`invite-status invite-status--${toKebab(
                      customerInvitationStatus
                    )}`"
                  >
                    {{ customerInvitationStatus }}
                  </span>
                </p>
              </v-flex>
              <v-flex xs12>
                <a
                  v-if="
                    !isModeView &&
                    pastQuotesButton === true &&
                    customerLastQuoted
                  "
                  :style="!customerLastQuoted ? 'margin: 0;' : ''"
                  @click="toggleShowPastQuotes"
                >
                  {{
                    !showPastQuotes ? 'Show Past Quotes' : 'Hide Past Quotes'
                  }}
                </a>
                <span
                  v-else-if="customerLastQuoted"
                  class="button-spacer"
                  :style="`${
                    $cr.breakpoint.lgAndUp ? 'width: 141px;' : 'width: 120px;'
                  }  height: 20px;`"
                ></span>
              </v-flex>
            </v-layout>
          </v-layout>
        </v-flex>
      </v-layout>
    </section>
    <section>
      <CustomerAccountCard
        v-if="customerAccountNameApproved"
        :quote-id="quoteId"
        :customer-id="value.customerId"
        :tier="accountTier"
      />
    </section>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import calls from '@/services/calls'
import customerAccounts from '@/services/customerAccounts'

import { phoneFormatFilter } from '@/utils/phone'
import { filter } from '@/utils/filter'
import { mask } from 'vue-the-mask'
import { authComputed, callCenterComputed } from '@/state/helpers'
import customers from '@/services/customers'
import industries from '@/services/industries'

import { deepClone } from '@/utils/deepClone'
import { isNotEmpty, isRequired, validateEmail } from '@/utils/validators'

import QuoteFormCustomerAccountForm from '@/components/QuoteFormCustomerAccountForm.vue'
import CustomerAccountCard from '@/components/CustomerAccountCard.vue'
import IndustrySelector from '@/components/IndustrySelector.vue'
import CRTag from '@/cr/components/CRTag.vue'

import { v4 } from 'uuid'
import { toKebab } from '../utils/string'
import { EventBus } from '@/utils/event-bus'

export default {
  filters: {
    phoneFormatFilter,
    nullInfoFilter(input) {
      return input || '---'
    },
  },
  components: {
    QuoteFormCustomerAccountForm,
    IndustrySelector,
    CRTag,
    CustomerAccountCard,
  },
  directives: { mask },
  inject: ['isInSidebarDialog'],
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          quoteFollowUpNotificationSetting: {
            isEnabled: true,
            label: "SMS Opt-In"
          }
        }
      },
    },
    userTitle: {
      type: String,
      default: 'Customer',
    },
    id: {
      type: String,
      default: null,
    },
    mode: {
      type: String,
      default: null,
    },
    isCustomerSelected: {
      type: Boolean,
    },
    pastQuotesButton: {
      type: Boolean,
    },
    showPastQuotes: {
      type: Boolean,
    },
    showLastQuote: {
      type: Boolean,
    },
    isNewDisplay: {
      type: Boolean,
    },
    industryRequired: {
      type: Boolean,
    },
    populatedFromLead: {
      type: Boolean,
      default: false,
    },
    tripId: {
      type: Number,
      default: null,
    },
    isEditingCustomer: {
      type: Boolean,
    },
    quoteId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      submitting: false,
      suggestedCustomers: [],
      hasCustomerFieldInputs: false,
      createNewSelected: false,
      customerFilters: filter(),
      customerLastQuoted: null,
      callId: null,
      previousCustomerId: null,
      customerInvitationStatus: '',
      shouldShowIndustryDescription: false,
      initialIndustryId: null,
      customerLoading: false,
      lastSearchTime: null,
      customerEmailTaken: null,
      isValidSearch: false,
      customerCreatedOn: null,
      customerAccount: null,
      customerSearchFields: {
        firstName: {
          column: {
            _t_id: '2610b5d1',
            prop: 'firstName',
            filterType: 'contains',
          },
        },
        lastName: {
          column: {
            _t_id: 'e5b0247a',
            prop: 'lastName',
            filterType: 'contains',
          },
        },
        phone: {
          column: { _t_id: '5db94195', prop: 'phone', filterType: 'contains' },
        },
        email: {
          column: { _t_id: '63f4ed7a', prop: 'email', filterType: 'contains' },
        },
        isSignedUpParent: {
          column: { _t_id: '9c5de701', prop: 'isSignedUp', filterType: 'eq' },
        },
        isSignedUpEmail: {
          column: { _t_id: '674257ba', prop: 'isSignedUp', filterType: 'eq' },
        },
      },
      customerFirstNameRules: [
        isRequired(true, isNotEmpty, {
          req: 'First Name Required',
          error: 'First Name Required',
        }),
      ],
      customerLastNameRules: [
        isRequired(true, isNotEmpty, {
          req: 'Last Name Required',
          error: 'Last Name Required',
        }),
      ],
      customerEmailRules: [
        (v) => !!v || 'Email Required',
        (v) => validateEmail(v) || 'Email required and must be valid',
        () => !this.customerEmailTaken || 'Email already exists in the system',
      ],
      customerPhoneRules: [
        isRequired(true, isNotEmpty, {
          req: 'Phone Required',
          error: 'Phone required and must be valid',
        }),
      ],
      bookingYearRules: [
        isRequired(true, isNotEmpty, {
          req: 'Bookings per Year Required',
          error: 'Bookings per Year required and must be valid',
        }),
      ],
      industryDescriptionRules: [
        isRequired(true, isNotEmpty, {
          req: 'Industry Description Required',
          error: 'Industry Description Required',
        }),
      ],
    }
  },
  computed: {
    ...authComputed,
    ...callCenterComputed,
    customerAccountId() {
      return this.value?.customerAccountId
    },
    isQuoteFollowUpNotificationSettingEnabled() {
      return this.value?.quoteFollowUpNotificationSetting?.isEnabled === true
    },
    quoteFollowUpNotificationSettingLabel() {
      return this.value?.quoteFollowUpNotificationSetting?.label
    },
    isModeView() {
      return this.mode === 'view'
    },
    isModeSidebarEdit() {
      return this.mode === 'sidebarEdit'
    },
    sameCustomerDifferentEmail() {
      if (!this.value.email) {
        return false
      }
      for (const suggestedCustomer of this.suggestedCustomers) {
        if (this.value.email === suggestedCustomer.email) {
          return false
        }
      }
      return true
    },
    customerNotesForDisplay() {
      return (
        this.value.customerNotes?.[0]?.html ||
        this.value.customerNotes?.[0]?.note ||
        this.value.notes
      )
    },
    customerNotesExist() {
      return (
        this.value.notes ||
        (this.value.customerNotes &&
          this.value.customerNotes?.[0] &&
          ((this.value.customerNotes?.[0]?.html &&
            this.value.customerNotes?.[0]?.html.length) ||
            (this.value.customerNotes?.[0]?.note &&
              this.value.customerNotes?.[0]?.note.length)))
      )
    },
    industryId() {
      return this.value.industryId
    },
    industryLabel() {
      let currentIndustry = this.value.industry
      let label = currentIndustry.label

      while (currentIndustry.parentIndustry) {
        currentIndustry = currentIndustry.parentIndustry
        label = currentIndustry.label.concat(' / ', label)
      }

      return label
    },
    industryDescription() {
      let currentIndustryDescription = this.value.industryDescription

      if (currentIndustryDescription?.length > 45) {
        currentIndustryDescription = currentIndustryDescription.substring(0, 44)
        currentIndustryDescription += '...'
      }

      return currentIndustryDescription
    },
    customerAccountNameApproved() {
      return this.customerAccount?.nameApprovedForUserInterface
    },
    accountTier() {
      return this.customerAccount.tier
    },
  },
  watch: {
    value: {
      handler() {
        this.$emit('input', this.value)
      },
    },
    'value.customerAccountId': {
      immediate: true,
      handler() {
        if (this.value.customerAccountId) {
          customerAccounts
            .getCustomerAccount(this.value.customerAccountId)
            .then(({ data }) => {
              this.customerAccount = data
              this.$emit('set-customer-account', data)
            })
            .catch((e) => console.error(e))
        } else {
          this.customerAccount = null
        }
      },
    },
    'value.customerId': {
      immediate: true,
      handler() {
        if (this.value.customerId) {
          customers
            .getCustomer(this.value.customerId)
            .then(({ data }) => {
              return data.customer
            })
            .then((customer) => {
              this.setCustomerStatus(customer)
              this.customerCreatedOn = customer.createdOn
              customer.id = customer.customerId
              this.$emit('input', customer)
            })
            .then(() => {
              this.getIndustry()
            })
            .catch((e) => console.error(e))
        }
      },
    },
    isCustomerSelected: {
      immediate: true,
      handler: async function (val) {
        if (!val) {
          return
        }

        this.getIndustry()

        let { customerId } = this.value
        if (customerId) {
          customers
            .getCustomer(customerId)
            .then(({ data }) => {
              this.setCustomerStatus(data.customer)
              this.customerCreatedOn = data.customer?.createdOn
            })
            .catch((e) => console.log(e))
        }
      },
    },
  },
  async mounted() {
    const userId = this.$route.query?.userId
    if (userId) {
      const customerInfo = {
        customerId: userId
      }

      let customer
      try {
        customer = await customers
          .getCustomerByExternalId(userId)
          .then(({ data }) => {
            return data.customer
          })
        this.selectCustomerFromList(null, customer)
      } catch (e) {
        console.error(e)
      }
      if (!customer) {
        try {
          const userData = await this.$store.dispatch(
            'users/getUserByExternalId',
            userId
          )
          const user = userData?.data?.user
          this.$emit('user-details-found', user)
          this.searchCustomerDetail('email', user?.email)
        } catch (e) {
          console.error(e)
        }
      }
    }
    EventBus.$on('customer-notes-update', (note, html) => {
      if (this.value.customerNotes && this.value.customerNotes[0]) {
        this.value.customerNotes[0].note = note
        this.value.customerNotes[0].html = html
      } else {
        this.value.customerNotes = []
        this.value.customerNotes.push({
          note: note,
          html: html,
        })
      }
    })
  },
  methods: {
    ...mapActions({
      setSelectedCustomer: 'quotes/setSelectedCustomer',
    }),
    toKebab(str) {
      return toKebab(str)
    },
    editCustomerNote(value) {
      const component = () => import('./AddContactNote.vue')
      this.$store.dispatch('app/openSidebarDialog', {
        reservationId: this.reservationId,
        data: {
          customerId: this.value.id,
          activeCustomer: this.value,
          title: 'Contact Notes',
        },
        component,
      })
    },
    async getIndustry() {
      if (!this.value.industryId) {
        return
      }
      const industryResponse = await industries.getIndustry(
        this.value.industryId
      )
      const industry = industryResponse?.data?.industry
      this.value = { ...this.value, industry }

      this.setShowIndustryDescription()
    },
    setShowIndustryDescription() {
      if (this.value.industry) {
        if (this.value.industry.isOther) {
          this.shouldShowIndustryDescription = true
          return
        }
      }
      this.shouldShowIndustryDescription = false
    },
    buildLastQuotedUrl(id) {
      let routeData = this.$router.resolve({ path: `/quotes/view/${id}/` })
      window.open(routeData.href, '_blank')
    },
    async searchCustomerDetail(field, value, immediate = false) {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }
      if (this.isEditingCustomer && field != 'email') {
        return
      }
      this.customerEmailTaken = null
      this.customerLoading = true
      this.lastSearchTime = Date.now()
      const currentSearchTime = this.lastSearchTime
      if (immediate) {
        this.suggestedCustomers = await this.searchCustomer(null)
        const emailMatch = this.suggestedCustomers.find(
          (customer) => customer.email === value
        )
        if (emailMatch) {
          this.selectCustomerFromList(emailMatch)
        }
        this.customerLoading = false
      } else {
        this.debounce = setTimeout(async () => {
          try {
            const suggestedSignedUpCustomers = await this.searchCustomer(true)
            let excludeEmails = []
            if (suggestedSignedUpCustomers) {
              excludeEmails = suggestedSignedUpCustomers.map(
                (item) => item.email
              )

              if (suggestedSignedUpCustomers.length < 5) {
                const suggestedNotSignedUpCustomers = await this.searchCustomer(
                  false,
                  excludeEmails
                )
                if (
                  suggestedNotSignedUpCustomers &&
                  suggestedNotSignedUpCustomers.length > 0
                ) {
                  const diff = 5 - suggestedSignedUpCustomers.length
                  for (let i = 0; i < diff; i++) {
                    if (suggestedNotSignedUpCustomers[i]) {
                      suggestedSignedUpCustomers.push(
                        suggestedNotSignedUpCustomers[i]
                      )
                    }
                  }
                }
              }
              this.suggestedCustomers = suggestedSignedUpCustomers
            }
            this.customerEmailTaken = this.suggestedCustomers.find(
              (customer) =>
                customer.email?.toUpperCase() === value.toUpperCase() &&
                customer.customerId !== this.value?.customerId &&
                this.isEditingCustomer
            )

            if (this.lastSearchTime === currentSearchTime) {
              this.customerLoading = false
              if (this.customerEmailTaken) {
                this.$refs.form.validate()
              }
            }
          } catch (err) {
            console.error(err)
          }
        }, 500)
      }
    },
    async searchCustomer(signedUp, excludeEmails) {
      this.resetCustomerFilters()
      this.isValidSearch = false
      let hasOtherFilters = false
      const { firstName, lastName, email, phone } = this.value
      if (firstName && firstName.length) {
        const firstNameFilter = this.customerSearchFields.firstName
        firstNameFilter.value = firstName.trim()
        firstNameFilter.column.filterAsIs = true
        this.customerFilters.add(this.parentAndFilter, firstNameFilter)
        hasOtherFilters = true
      }
      if (lastName && lastName.length) {
        const lastNameFilter = this.customerSearchFields.lastName
        lastNameFilter.value = lastName.trim()
        lastNameFilter.column.filterAsIs = true
        this.customerFilters.add(this.parentAndFilter, lastNameFilter)
        hasOtherFilters = true
      }
      if (phone && phone.length) {
        const phoneFilter = this.customerSearchFields.phone
        phoneFilter.value = ('' + phone).replace(/\D/g, '')
        this.customerFilters.add(this.parentAndFilter, phoneFilter)
        hasOtherFilters = true
      }
      if (email && email.length) {
        const emailFilter = this.customerSearchFields.email
        emailFilter.value = email.trim()
        emailFilter.column.filterAsIs = true
        this.customerFilters.add(this.parentAndFilter, emailFilter)
        hasOtherFilters = true
        if (typeof signedUp === 'boolean') {
          const isSignedUpEmail = this.customerSearchFields.isSignedUpEmail
          isSignedUpEmail.value = signedUp
          this.customerFilters.add(this.parentAndFilter, isSignedUpEmail)
        }
      }
      if (hasOtherFilters) {
        this.isValidSearch = true
        if (typeof signedUp === 'boolean') {
          const isSignedUpParent = this.customerSearchFields.isSignedUpParent
          isSignedUpParent.value = signedUp
          this.customerFilters.add(this.parentAndFilter, isSignedUpParent)
        }
      }

      const params = {
        filters: this.customerFilters.asQueryParams(),
        pageSize: 5,
      }
      const matchedCustomers = await customers.getCustomers(params)
      this.hasCustomerFieldInputs = true
      const suggestedCustomers = matchedCustomers?.data?.resultList || []

      this.resetCustomerFilters()
      if (email && validateEmail(email) && suggestedCustomers.length == 0) {
        const matchedEmailFilter = this.customerSearchFields.email
        matchedEmailFilter.value = email.trim()
        matchedEmailFilter.column.filterAsIs = true
        this.customerFilters.add(this.parentAndFilter, matchedEmailFilter)
        if (typeof signedUp === 'boolean') {
          const isSignedUpEmailMatched = this.customerSearchFields
            .isSignedUpEmail
          isSignedUpEmailMatched.value = signedUp
          this.customerFilters.add(this.parentAndFilter, isSignedUpEmailMatched)
        }

        const params = {
          filters: this.customerFilters.asQueryParams(),
          pageSize: 5,
        }
        const matchedEmailCustomers = await customers.getCustomers(params)

        const suggestedMatchedCustomers =
          matchedEmailCustomers?.data?.resultList || []
        return suggestedMatchedCustomers
      }

      return suggestedCustomers
    },
    async saveAndProceed() {
      if (!this.validate()) {
        return
      }
      const customer = Object.assign({ customerId: this.value.id }, this.value)
      await this.customerUpdate(customer)

      this.$emit('input', {
        id: customer.customerId,
        ...customer,
      })
      this.$emit('set-is-customer-selected', true)
      this.$emit('set-is-editing-customer', false)

      const currentCustomer = await customers.getCustomer(customer.customerId)?.data
      this.setCustomerStatus(currentCustomer)
      this.customerCreatedOn = currentCustomer?.createdOn
    },
    async customerUpdate(customer) {
      customer.companyId = this.currentUser?.companyId
      customer.customerAccountId = this.value.customerAccountId
      customer.phone = this.value.phone?.replace(/[^0-9]/g, '')
      customer.bookings = customer.bookings === '--' ? null : customer.bookings
      if (!customer.bookings) {
        return
      }

      if (customer.customerId) {
        await customers.updateCustomer({
          id: customer.customerId,
          payload: customer,
        })
      }
    },
    cancelEditingCustomer() {
      this.$emit('input', this.currentCustomer)
      this.suggestedCustomers = []
      this.customerEmailTaken = null
      this.currentCustomer = null
      this.$emit('set-is-customer-selected', true)
      this.$emit('set-is-editing-customer', false)
    },
    async selectCustomerFromList(customerListItem, customer = null) {
      if (!customer) {
        customer = await customers
        .getCustomer(customerListItem.customerId)
        .then(({ data }) => {
          return data.customer
        })
      }
      this.$emit('set-is-editing-customer', false)
      this.$emit('set-is-customer-selected', true)
      customer.id = customer.customerId
      this.initialIndustryId = customer.industryId
      this.setCustomerStatus(customer)
      this.$emit('input', customer)
      this.customerEmailTaken = null
      this.$refs.form.validate()
      if (this.isInSidebarDialog) {
        EventBus.$emit('set-is-customer-selected', true)
        EventBus.$emit('input:user-selected', customer)
      }

      if (this.hasCallCenter && this.isOnCall) {
        const params = {
          callSid: this.currentCallCenter.call.callSid,
          userId: this.currentUser.userId,
        }
        const callIdResponse = await calls.getCallIdByCallSid(params)
        this.callId = callIdResponse.data?.call?.callId

        let updateParams = {
          previousUserId: this.previousCustomerId
            ? this.previousCustomerId
            : customer.id,
          newUserId: customer.id,
          callId: this.callId,
        }

        const response = await customers
          .updateUserCalls(updateParams)
          .catch((error) => {
            console.log(error)
            return false
          })
        this.previousCustomerId = response.data
      }
    },
    setCustomerStatus(customer) {
      if (customer?.lastQuote) {
        this.customerLastQuoted = customer.lastQuote
        this.customerInvitationStatus = customer.isSignedUp
          ? 'Signed Up'
          : 'New'
      }
    },
    resetCustomerFilters() {
      this.customerFilters.clear()
      this.grandParentFilter = this.customerFilters.createParent('or')
      this.parentAndFilter = this.customerFilters.createParent(
        'and',
        this.grandParentFilter
      )
      this.parentHiddenOrFilter = this.customerFilters.createParent(
        'or',
        this.grandParentFilter
      )
      this.parentOrFilter = this.customerFilters.createParent(
        'and',
        this.parentHiddenOrFilter
      )
    },
    resetCustomerSelection() {
      this.$emit('set-is-editing-customer', false)
      if (this.isModeSidebarEdit) {
        this.resetCustomerSelectionItinerary()
      } else {
        this.suggestedCustomers = []
        this.customerLastQuoted = null
        this.customerInvitationStatus = ''
        this.shouldShowIndustryDescription = false
        this.customerCreatedOn = null
        this.initialIndustryId = null

        this.$nextTick(() => {
          this.resetCustomerFilters()
          this.$emit('input', {
            firstName: '',
            lastName: '',
            email: '',
            bookings: '',
            customerId: null,
            industryId: null,
          })

          this.$emit('set-is-customer-selected', false)

          if (this.showPastQuotes) {
            this.toggleShowPastQuotes()
          }
          if (this.showLastQuote) {
            this.toggleShowLastQuote()
          }
        })
      }
    },
    editCustomerSelection() {
      this.$emit('set-is-editing-customer', true)
      this.currentCustomer = deepClone(this.value)
      if (this.isModeSidebarEdit) {
        this.editCustomerSelectionSidebar()
      } else {
        this.initialIndustryId = this.value?.industryId
        this.$emit('set-is-customer-selected', false)

        if (this.showPastQuotes) {
          this.toggleShowPastQuotes()
        }
        if (this.showLastQuote) {
          this.toggleShowLastQuote()
        }
      }
    },
    editCustomerSelectionSidebar() {
      const component = () => import('./TripContactSidebar.vue')
      const tripContact = {
        id: this.value?.id,
        firstName: this.value?.firstName,
        lastName: this.value?.lastName,
        email: this.value?.email,
        phone: this.value?.phone,
        organization: this.value?.organization,
        bookings: this.value?.bookings,
        customerAccount: this.value?.customerAccount,
      }
      this.$store.dispatch('app/openSidebarDialog', {
        data: {
          tripId: this.tripId,
          value: tripContact,
          title: 'Trip Contact',
          isCustomerSelected: true,
        },
        component,
      })
    },
    async resetCustomerSelectionItinerary() {
      const params = {
        tripId: this.tripId,
        payload: {
          tripContact: {
            customerId: null,
          },
        },
      }

      try {
        await this.$store.dispatch('trips/editTripContact', params)
      } catch (err) {
        this.showAlert({
          type: 'error',
          message: 'Error setting trip contact, please try again.',
        })
        console.error(err)
      }
      EventBus.$emit('refresh-detail')
    },
    async submitNewCustomerForm(immediate = false) {
      EventBus.$emit('user-creation-in-progress', true)
      const isValid = this.validate()
      if (!isValid) {
        EventBus.$emit('user-creation-in-progress', false)
        return
      }

      this.submitting = true

      let {
        email,
        phone: phone,
        firstName,
        lastName,
        bookings,
        organization,
        industryId,
      } = this.value

      firstName = firstName || this.value?.firstName
      lastName = lastName || this.value?.lastName
      email = email || this.value?.email
      if (typeof phone === 'string') {
        phone = phone.replace(/[^0-9]/g, '')
      }
      phone = phone || this.value?.phone?.replace(/[^0-9]/g, '')
      bookings = bookings || this.value?.bookings
      organization = organization || this.value?.organization

      const companyId = this.currentUser?.companyId

      if (bookings === '--') {
        bookings = null
      }

      if (!email) {
        const newRandomEmail = this.uuidv4().substring(0, 8)
        email = `tripContact${newRandomEmail}@coachrail.com`
      }

      const payload = {
        firstName,
        lastName,
        phone,
        email,
        companyId,
        industryId,
        customerAccountId: this.value.customerAccount?.customerAccountId,
        bookings,
        organization,
      }

      if (this.shouldShowIndustryDescription) {
        payload.industryDescription = this.value.industryDescription
      }

      if (
        this.hasCallCenter &&
        this.isOnCall &&
        this.currentCallCenter.call?.callSid
      ) {
        const params = {
          callSid: this.currentCallCenter.call.callSid,
          userId: this.currentUser.userId,
        }
        const callIdResponse = await calls.getCallIdByCallSid(params)
        this.callId = callIdResponse.data?.call?.callId
      }

      if (
        this.hasCallCenter &&
        this.isOnCall &&
        this.populatedFromLead &&
        this.currentCallCenter.call
      ) {
        payload.callId = this.callId
      }

      try {
        const addCustomerResponse = await customers.addCustomer({ payload })
        if (addCustomerResponse.status === 200) {
          await this.searchCustomerDetail('email', email, immediate)
          await this.getIndustry()
          this.hasCustomerFieldInputs = false
        }
        this.previousCustomerId = addCustomerResponse.data.customer.userId
      } catch (err) {
        console.error(err)
      } finally {
        this.submitting = false
        EventBus.$emit('user-creation-in-progress', false)
      }
    },
    validate() {
      if (
        this.$refs.industrySelector &&
        !this.$refs.industrySelector.validate()
      ) {
        return false
      }
      return this.$refs.form.validate()
    },
    async onNewCustomerAccount(newCustomerAccount) {
      this.createNewSelected = false
      this.$emit('new-customer-account', newCustomerAccount)
      await this.$refs['customer-account-selector'].searchCustomerAccounts()
    },
    toggleShowPastQuotes() {
      if (this.showLastQuote) {
        this.toggleShowLastQuote()
      }
      this.$emit('toggle-show-past-quotes')
    },
    toggleShowLastQuote() {
      if (this.customerLastQuoted?.quoteId) {
        this.$emit('toggle-show-last-quote', this.customerLastQuoted?.quoteId)
      } else {
        this.$emit('toggle-show-last-quote', null)
      }
    },
    uuidv4() {
      return v4()
    },
  },
}
</script>

<style lang="scss" scoped>
.customer-select-info {
  padding: 20px 0;

  p {
    margin: 0;
  }

  a {
    font-weight: bold;
    cursor: pointer;
  }

  .right-panel {
    p {
      font-weight: bold;
    }
  }

  .user-select-buttons-sm {
    button {
      margin: 0;
    }
  }

  .customer-text-light {
    color: $shofur-text-light;
    font-weight: 500;
  }

  .lg-divider {
    height: 60px;
    width: 1px;
    margin: 0 15px;
    background-color: $border-gray;
  }

  .sm-divider {
    width: 1px;
    background-color: $border-gray;
    margin: 0 5px;
    max-height: 25px;
  }

  .invite-status {
    padding: 3px 6px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 11px;
    border-radius: 2px;
    margin: 0 4px;

    &--signed-up {
      background: $green;
      color: white;
    }

    &--new {
      background: $primary;
      color: white;
    }
  }
}

.edit-button {
  cursor: pointer;
  font-weight: bold;
  color: $primary;
  font-size: 13px;
  padding: 7px;
}

.customer-info {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;

  .customer-header-actions {
    flex-grow: 0.1;
    padding-right: 10px;
  }

  .customer-name {
    flex-grow: 0.1;
    padding-right: 10px;
    margin-bottom: 10px;
  }

  .customer-phone {
    flex-grow: 0.1;
    padding-right: 10px;
  }

  .customer-account {
    flex-grow: 0.1;
    padding-right: 10px;
  }

  .customer-email {
    flex-grow: 0.1;
    padding-right: 10px;
  }

  .customer-quotes {
    flex-grow: 0.4;
    margin-top: 10px;
    padding-right: 10px;
  }

  .icon-padded {
    padding: 10px;
  }

  .remove-icon {
    margin: 0px 0px auto;

    :hover {
      color: $red !important;
    }
  }
}

.customer-suggestion-row {
  display: flex;
  flex-direction: row;
}

.sms-opt-out-chip {
  display: flex;
  border-radius: 50px;
  padding: 3px 8px;
  height: 100%;
  align-items:center;
  justify-content: center;
  font-weight: bold;
  overflow: hidden;
  color: $white;

  &--true {
      background: $midnight-blue;
    }

  &--false {
      background: $error-new;
    }
}

.banned-red {
  background-color: $error-pale !important;
  border: 1px solid $error;
  border-radius: 13px;
  .cr-icon {
    color: $error;
  }
}
</style>

<template>
  <v-layout column class="customer-column">
    <v-flex class="inner-container">
      <h2 class="spacing">{{ title }}</h2>

      <v-form ref="customer-account">
        <CRInput
          id="quote-form-customer-account-form-company-name"
          v-model="model.name"
          :floatinglabel="'Company Name'"
        />

        <CRInput
          id="quote-form-customer-account-form-company-address"
          :floatinglabel="'Address'"
          type="address"
          :manual-control="true"
          @place-selected="placeSelected"
        />

        <CRInput
          id="quote-form-customer-account-form-company-email"
          v-model="model.email"
          :floatinglabel="'Primary Email Address'"
        />

        <CRInput
          id="quote-form-customer-account-form-company-phone"
          v-model="model.phone"
          v-mask="['(###) ###-####', '+## ## #### ####']"
          :floatinglabel="'Phone Number'"
        />
        <div>
          <label>Industry</label>
          <CRSelect
            id="quote-form-customer-account-form-company-industry"
            v-model="model.industryId"
            item-value="industryId"
            item-text="label"
            :items="availableIndustries"
            :menu-props="{ closeOnContentClick: true }"
          />
        </div>

        <div class="justify-right">
          <div class="sub-bar">
            <v-btn
              id="quote-form-customer-account-form-cancel-button"
              class="btn-secondaryaction"
              @click.stop.prevent="cancel"
            >
              Cancel
            </v-btn>
            <v-btn
              id="quote-form-customer-account-form-create-button"
              class="btn-primaryaction"
              @click.stop.prevent="create"
            >
              Create
            </v-btn>
          </div>
        </div>
      </v-form>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapActions } from 'vuex'
import { mask } from 'vue-the-mask'
import customerAccounts from '@/services/customerAccounts'
import industries from '@/services/industries'
import { authComputed } from '@/state/helpers'

export default {
  directives: { mask },
  props: {
    title: {
      type: String,
      default: 'Create Customer Account',
    },
  },
  data() {
    return {
      model: {
        name: '',
        address: null,
        email: '',
        phone: '',
        industryId: '',
      },
      availableIndustries: [],
    }
  },
  computed: {
    ...authComputed,
  },
  async mounted() {
    this.availableIndustries = await industries
      .getIndustries({
        pageSize: 100,
      })
      .then(({ data: { resultList } }) => {
        return resultList
      })
  },
  methods: {
    ...mapActions({
      setSelectedAccount: 'quotes/setSelectedCustomerAccount',
      addAvailableAccounts: 'quotes/addAvailableAccounts',
    }),
    placeSelected(evt) {
      this.model.address = evt.place
    },
    async create() {
      const newAcc = JSON.parse(JSON.stringify(this.model))
      newAcc.companyId = this.currentUser?.companyId
      newAcc.phone = newAcc.phone.replace(/[^0-9]/g, '')
      const { data } = await customerAccounts.addCustomerAccount(newAcc)
      newAcc.customerAccountId = data
      this.$emit('on-submit', newAcc)
    },
    cancel() {
      this.$emit('on-cancel')
    },
  },
}
</script>
<style lang="scss" scoped>
.customer-column {
  align-items: center;

  .inner-container {
    width: 70%;

    .spacing {
      margin-top: 30px;
      margin-bottom: 5%;
    }

    .justify-right {
      display: flex;
      justify-content: flex-end;

      .sub-bar {
        position: relative;
        left: 8px;
      }
    }
  }
}
</style>

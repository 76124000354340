import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { Customer, CustomerResponseDTO } from '@/models/dto/Customer'
import { ApiResult } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  getCustomers(params: TableViewParameters): Promise<AxiosResponse<TableViewResult<Customer>>> {
    const { pageSize = 10, page = 1, sorts = null, filters = null } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)

    const host = baseUrl()
    const url = `https://${host}/tables/customers?${query}&v2=true`
    return httpService.get(url)
  },
  getCustomer(customerId: number): Promise<AxiosResponse<CustomerResponseDTO>> {
    const host = baseUrl()
    const url = `https://${host}/customers/${customerId}`
    return httpService.get(url)
  },
  getCustomerByExternalId(externalCustomerId: string) {
    const host = baseUrl()
    const url = `https://${host}/customers/externalId/${externalCustomerId}`
    return httpService.get(url)
  },
  updateCustomer(params: { id: number, payload: Customer }): Promise<AxiosResponse<ApiResult>> {
    const host = baseUrl()
    const url = `https://${host}/customers/${params.id}`
    return httpService.patch(url, params.payload)
  },
  updateUserCalls(params): Promise<AxiosResponse<ApiResult>> {
    const host = baseUrl()
    const url = `https://${host}/customers/updateUserCalls/${params.previousUserId}/${params.newUserId}/${params.callId}`
    return httpService.post(url, params.payload)
  },
  modifyCustomer(params): Promise<AxiosResponse<ApiResult>> {
    const host = baseUrl()
    let url = `https://${host}/customers/${params.id}`

    return httpService.patch(url, params.payload)
  },
  addCustomer(params): Promise<AxiosResponse<ApiResult>> {
    const host = baseUrl()
    const url = `https://${host}/customers`
    return httpService.post(url, params.payload)
  },
  deleteCustomer(customerId: number): Promise<AxiosResponse<ApiResult>> {
    const host = baseUrl()
    const url = `https://${host}/customers/${customerId}`
    return httpService.delete(url)
  },
  exportCustomers(params: TableViewParameters): Promise<AxiosResponse<any>> {
    const { pageSize = 10, page = 1, sorts = null, filters = null } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)

    const host = baseUrl()
    const url = `https://${host}/customers/export?${query}`
    return httpService.get(url)
  },
  getCustomerAccountDefaultsByCustomer(customerId: number): Promise<AxiosResponse<ApiResult>> {
    const host = baseUrl()
    const url = `https://${host}/customers/${customerId}/effectiveDefaultValueSet`
    return httpService.get(url)
  },
  sendCustomerForgotPasswordEmail(payload: { email: string }): Promise<AxiosResponse<ApiResult>> {
    const host = baseUrl()
    const url = `https://${host}/customers/customerForgotPassword`
    return httpService.post(url, payload)
  },
  sendCustomerInviteEmail(customerId: number): Promise<AxiosResponse<ApiResult>> {
    const host = baseUrl()
    const url = `https://${host}/customers/${customerId}/sendInviteEmail`
    return httpService.post(url, {})
  },
  getCustomerServiceTier(customerId: number): Promise<AxiosResponse<ApiResult>> {
    const host = baseUrl()
    const url = `https://${host}/customers/${customerId}/domain-service-tier`
    return httpService.get(url)
  }
}

import { TierStyle } from "@/models/Tier"

export const DESCRIPTION_PLACEHOLDER = '{entity}'
export const TIER_STYLES = {
  'vip': {
    leftIcon: {
      name: 'mdi-crown',
      isCRIcon: false,
    },
    colors: {
      background: '#FDCF2C',
      text: 'black'
    },
    label: 'VIP',
  },
  'plus': {
    leftIcon: {
      name: 'charter_up_c',
      isCRIcon: true,
    },
    rightIcon: {
      name: 'add',
      isCRIcon: false,
    },
    colors: {
      background: '#08A6F0',
      text: 'white'
    },
    label: 'CharterUP'
  }
}

export const getDescription = (template: string, entity: string): string => {
  if (template && entity) {
    return template.replace(DESCRIPTION_PLACEHOLDER, entity)
  }
  return ''
}

export const getStyles = (key: string): TierStyle => {
  if (TIER_STYLES.hasOwnProperty(key)) {
    return TIER_STYLES[key]
  }
  return null
}

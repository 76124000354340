<template>
  <div>
    <div
      v-for="(industry, index) in industriesList"
      :key="`industry-selector-autocomplete-industry-select-${index}`"
    >
      <label v-if="index === 0">Industry</label>
      <label v-else>Sub-Industry</label>
      <v-autocomplete
        :id="`industry-selector-autocomplete-industry-select-${index}`"
        v-model="industrySelections[index]"
        :value="value"
        :disabled="isModeView"
        item-value="industryId"
        item-text="label"
        solo
        flat
        :items="industry"
        :error-messages="validationError[index]"
        :menu-props="{ closeOnContentClick: true }"
        return-object
        clearable
        append-icon="keyboard_arrow_down"
        placeholder="Industry"
        @click:clear="clearSelectedIndex(index)"
        @change="checkSelectedIndustry(index, true)"
      ></v-autocomplete>
    </div>
  </div>
</template>
<script>
import industries from '@/services/industries'
import { sort } from '@/utils/sort'

export default {
  props: {
    value: {
      type: Number,
      default: null,
    },
    mode: {
      type: String,
      default: 'edit',
    },
    enterpriseAccountId: {
      type: String,
      default: '',
    },
    initialIndustry: {
      type: Number,
      default: null,
    },
    industryRequired: {
      type: Boolean,
    },
  },
  data() {
    return {
      select: null,
      search: '',
      immediate: true,
      industrySelections: [],
      industriesList: [],
      validationError: [],
      hasSubIndustries: false,
    }
  },
  computed: {
    isModeView() {
      return this.mode === 'view'
    },
  },
  watch: {
    value() {
      this.$emit('input', this.value)
    },
    initialIndustry() {
      if (this.initialIndustry && this.industrySelections.length === 0) {
        this.populateInitialIndustries()
      }
    },
  },
  async mounted() {
    this.getAvailableIndustries()
    if (this.initialIndustry) {
      this.populateInitialIndustries().then(
        this.$emit('input', this.initialIndustry)
      )
    }
  },
  methods: {
    validate() {
      if (!this.hasSubIndustries && (this.value || !this.industryRequired)) {
        return true
      }
      for (let i = 0; i < this.industriesList.length - 1; i += 1) {
        this.validationError.push('')
      }
      if (this.industriesList.length === 1) {
        this.validationError.push('Industry Required')
      } else {
        this.validationError.push('Sub Industry Required')
      }
      return false
    },
    clearSelectedIndex(index) {
      this.industriesList.splice(index)
      this.industrySelections.splice(index)
      if (index > 0) {
        this.checkSelectedIndustry(index - 1)
      } else {
        this.getAvailableIndustries()
      }

      this.$emit('input', null)
    },
    async populateInitialIndustries() {
      const industryResponse = await industries.getIndustry(
        this.initialIndustry
      )
      let industryData = industryResponse.data?.industry
      this.industrySelections.push(industryData)

      while (industryData.parentIndustry) {
        this.industrySelections.unshift(industryData.parentIndustry)
        industryData = industryData.parentIndustry
      }

      for (const [index] of this.industrySelections.entries()) {
        await this.checkSelectedIndustry(index, false, true)
      }
      this.$forceUpdate()
    },
    async getAvailableIndustries() {
      const industrySort = sort()

      industrySort.add({
        prop: 'label',
        direction: 'asc',
      })

      const sortParams = industrySort.asQueryParams()

      const availableParentIndustriesData = await industries
        .getIndustries({
          page: 1,
          pageSize: -1,
          sorts: sortParams,
        })
        .then((data) => data.data)

      let availableParentIndustries = availableParentIndustriesData.resultList
      availableParentIndustries = availableParentIndustries.filter(
        (industry) => industry.parentIndustryId === null
      )
      this.industriesList.push(availableParentIndustries)
    },
    async checkSelectedIndustry(
      selectionIndex,
      clearOld = false,
      initialPopulation = false
    ) {
      if (
        clearOld &&
        (this.industrySelections[selectionIndex + 1] ||
          this.industriesList[selectionIndex + 1])
      ) {
        this.clearSelectedIndex(selectionIndex + 1)
        return
      }
      const lastSelectedParent = this.industrySelections[selectionIndex]

      try {
        const subIndustriesResponse = await industries.getSubIndustries(
          lastSelectedParent.industryId
        )
        const subIndustries = subIndustriesResponse.data?.industries
        if (subIndustries !== null && subIndustries.length !== 0) {
          this.industriesList[selectionIndex + 1] =
            subIndustriesResponse.data?.industries
          this.hasSubIndustries = true
        } else {
          this.hasSubIndustries = false
        }
      } catch {
        // no sub-industries found
      }

      if (!initialPopulation) {
        this.$emit('input', this.industrySelections[selectionIndex]?.industryId)
        this.$emit('is-other', this.industrySelections[selectionIndex]?.isOther)
      }

      this.validationError = []
    },
  },
}
</script>
<style lang="scss" scoped>
.create-account {
  height: 40px;
  padding: 0 16px;
  font-size: 16px;
  line-height: 2;
  color: $blue;
  cursor: pointer;

  &.clear-account {
    color: $red;
  }
}

::v-deep.customer-account-divider {
  margin: 0 !important;
}
</style>

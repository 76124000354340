import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'

const httpService: HttpService = new HttpService()

export default {
  getExchangeRate(): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/exchangeRate`
    return httpService.get(url)
  },
}

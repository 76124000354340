<template>
  <v-layout row wrap align-start class="customer-account-info">
    <!-- Customer Account Info -->
    <v-flex>
      <v-layout row justify-start align-center class="mx-2">
        <img src="@/assets/building.svg" class="building-icon" />
        <v-layout column>
          <v-flex xs4>
            <div class="customer-account-name-label">Account</div>
            <v-layout row justify-start align-center>
              <div class="d-flex align-center">
                <v-tooltip right>
                  <template #activator="{ on, attrs }">
                    <div class="d-flex" v-on="on">
                      <b>{{ customerAccount.name }}</b>
                      <div
                        class="margin-l-2 margin-b-1"
                        v-if="isTiersEnabled && showTierMarker"
                      >
                        <TierBadge :tier="tier" />
                      </div>
                    </div>
                  </template>
                  <span>
                    {{ hierarchyDisplay }}
                  </span>
                </v-tooltip>
              </div>
            </v-layout>
          </v-flex>
          <v-flex xs4 class="mb-2">
            <div class="customer-account-name">
              Account Executive:
              <template v-if="effectiveDefaultValueSet.accountExecutive">
                {{ effectiveDefaultValueSet.accountExecutive.firstName }}
                {{ effectiveDefaultValueSet.accountExecutive.lastName }}
              </template>
            </div>
          </v-flex>
          <v-flex xs4>
            <b>Account Notes:</b>
            {{ customerAccount.notes }}
          </v-flex>
        </v-layout>
      </v-layout>
    </v-flex>

    <v-flex grow></v-flex>
    <!-- Customer Account Defaults -->
    <v-flex shrink>
      <v-layout row align-center class="right-panel">
        <!-- Vertical Divider -->
        <span v-if="$cr.breakpoint.mdAndUp" class="lg-divider" />
        <v-layout
          row
          justify-end
          shrink
          wrap
          class="margin-l-6"
          style="width: 500px"
        >
          <v-flex grow>
            <p>
              <span class="customer-account-default-label">Payment Terms:</span>
              {{ paymentTermsDays }}
            </p>
            <p>
              <span class="customer-account-default-label">Discount:</span>
              {{ discountPercent }}
            </p>
            <p>
              <span class="customer-account-default-label">Markup:</span>
              {{ markupPercent }}
            </p>
            <p>
              <span class="customer-account-default-label">
                Pricing Method:
              </span>
              {{ pricingMethod }}
            </p>
            <p>
              <span class="customer-account-default-label">
                Payment Method:
              </span>
              {{ paymentMethodType }}
            </p>
            <p>
              <span class="customer-account-default-label">Marge:</span>
              {{ margeEnabled }}
            </p>
            <p v-if="operatorQualityMinIsSet">
              <span class="customer-account-default-label">
                Operator Quality Min:
              </span>
              {{ operatorQualityMin }}
            </p>
          </v-flex>
        </v-layout>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import TierBadge from '@/components/TierBadge.vue'
import customerAccounts from '@/services/customerAccounts'
import { SplitFeatureFlag } from '@/utils/enum'
import { mapActions } from 'vuex'

const PRICING_METHODS_MAP = [
  {
    key: 'single_price',
    label: 'Single Price',
  },
  {
    key: 'bids',
    label: 'Bids',
  },
  {
    key: 'category',
    label: 'Category',
  },
]

export default {
  components: {
    TierBadge,
  },
  props: {
    quoteId: {
      type: Number,
      default: null,
    },
    customerId: {
      type: Number,
      required: true,
    },
    tier: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      customerAccount: {},
      customerAccountDefaults: {},
      effectiveDefaultValueSet: {},
      customerAccountHierarchy: [],
      isTiersEnabled: false,
    }
  },
  computed: {
    pricingMethod() {
      if (this.customerAccountDefaults?.pricingMethodKey) {
        const foundPricingMethod = PRICING_METHODS_MAP.find(
          (pm) => pm.key === this.customerAccountDefaults.pricingMethodKey
        )
        return foundPricingMethod?.label
      }
      if (this.effectiveDefaultValueSet?.pricingMethodKey) {
        const foundPricingMethod = PRICING_METHODS_MAP.find(
          (pm) => pm.key === this.effectiveDefaultValueSet.pricingMethodKey
        )
        return `${foundPricingMethod?.label} (Inherited)`
      }
      return '--'
    },
    paymentMethodType() {
      if (this.customerAccountDefaults?.paymentMethodType) {
        return this.customerAccountDefaults?.paymentMethodType?.label
      }
      if (this.effectiveDefaultValueSet?.paymentMethodType) {
        return `${this.effectiveDefaultValueSet?.paymentMethodType?.label} (Inherited)`
      }
      return '--'
    },
    paymentTermsDays() {
      if (this.customerAccountDefaults?.paymentTermsDays) {
        return `Net ${this.customerAccountDefaults.paymentTermsDays}`
      }
      if (this.effectiveDefaultValueSet?.paymentTermsDays) {
        return `Net ${this.effectiveDefaultValueSet.paymentTermsDays} (Inherited)`
      }
      return '--'
    },
    discountPercent() {
      if (this.customerAccountDefaults?.discountPercent) {
        return `${this.customerAccountDefaults.discountPercent}%`
      }
      if (this.effectiveDefaultValueSet?.discountPercent) {
        return `${this.effectiveDefaultValueSet.discountPercent}% (Inherited)`
      }
      return '--'
    },
    markupPercent() {
      if (this.customerAccountDefaults?.markupPercent) {
        return `${this.customerAccountDefaults.markupPercent}%`
      }
      if (this.effectiveDefaultValueSet?.markupPercent) {
        return `${this.effectiveDefaultValueSet.markupPercent}% (Inherited)`
      }
      return '--'
    },
    margeEnabled() {
      if (this.customerAccountDefaults?.needsManualReferral === false) {
        return 'Yes'
      } else if (this.customerAccountDefaults?.needsManualReferral === true) {
        return 'No'
      }
      if (this.effectiveDefaultValueSet?.needsManualReferral === false) {
        return 'Yes (Inherited)'
      } else if (this.effectiveDefaultValueSet?.needsManualReferral === true) {
        return 'No (Inherited)'
      }
      return '--'
    },
    operatorQualityMin() {
      if (this.customerAccountDefaults?.minQuality) {
        return this.customerAccountDefaults.minQuality
      }
      if (this.effectiveDefaultValueSet?.minQuality) {
        return `${this.effectiveDefaultValueSet.minQuality} (Inherited)`
      }
      return '--'
    },
    operatorQualityMinIsSet() {
      return (
        this.customerAccountDefaults?.minQuality !== null ||
        this.effectiveDefaultValueSet?.minQuality !== null
      )
    },
    hierarchyDisplay() {
      return this.customerAccountHierarchy.map((h) => h.name).join(' > ')
    },
    showTierMarker() {
      if (this.tier) {
        return this.tier?.level > 1
      }
      return false
    },
  },
  watch: {
    customerId() {
      this.loadData()
    },
  },
  async mounted() {
    this.loadData()
    this.isTiersEnabled = await this.isFeatureEnabled(
      SplitFeatureFlag.ServiceTier
    )
  },
  methods: {
    ...mapActions({
      isFeatureEnabled: 'split/isFeatureEnabled',
    }),
    async loadData() {
      if (!this.customerId) {
        return
      }
      const response = await customerAccounts.getCustomerAccountCard(
        this.quoteId || -1,
        this.customerId
      )
      this.customerAccount = response?.data?.customerAccount
      this.customerAccountDefaults =
        response?.data?.customerAccountDefaultValueSet
      this.effectiveDefaultValueSet = response?.data?.effectiveDefaultValueSet
      this.customerAccountHierarchy = response?.data?.customerAccountHierarchy
    },
  },
}
</script>
<style lang="scss" scoped>
.customer-account-info {
  margin-top: 20px;
  padding: 20px 0;
  margin-bottom: 20px;
}
.customer-account-name-label {
  color: $shofur-text-light;
  font-weight: 500;
}
.customer-account-default-label {
  color: $shofur-text-light;
  font-weight: 500;
}
.customer-account-default {
  margin-bottom: 10px;
}

.building-icon {
  margin: 0 20px;
}

.right-panel {
  justify-content: flex-end;
  * > p {
    margin: 0;
  }

  .lg-divider {
    height: 60px;
    width: 1px;
    margin: 0 15px;
    background-color: $border-gray;
  }
}
</style>

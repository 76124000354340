
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Tier, TierIcon, TierStyle } from '@/models/Tier'
import { getStyles } from '@/utils/tiers'

@Component
export default class TierBadge extends Vue {
  @Prop({ type: Object, required: true }) readonly tier: Tier

  get styles(): TierStyle {
    return getStyles(this.tier?.key)
  }

  get leftIcon(): TierIcon {
    return this.styles?.leftIcon
  }

  get rightIcon(): TierIcon {
    return this.styles?.rightIcon
  }

  get label(): string {
    return this.styles?.label
  }

  get backgroundColor(): any {
    return this.styles?.colors?.background
  }

  get textColor(): any {
    return this.styles?.colors?.text
  }
}

import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { Industry } from '@/models/dto/Industry'

const httpService: HttpService = new HttpService()

export default {
  getIndustries(params: TableViewParameters): Promise<AxiosResponse<TableViewResult<Industry>>> {
    const { pageSize = 10, page = 1, sorts = null, filters = null } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)

    const host = baseUrl()
    const url = `https://${host}/tables/industries?${query}`
    return httpService.get(url)
  },
  getIndustry(industryId: number): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/industry/${industryId}`
    return httpService.get(url)
  },
  getSubIndustries(industryId: number): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/industry/${industryId}/getSubIndustries`
    return httpService.get(url)
  },
  modifyIndustry(params: { id: number, payload: Industry}): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/industry/${params.id}`
    return httpService.patch(url, params.payload)
  },
  addIndustry(payload: Industry): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/industry`
    return httpService.post(url, payload)
  },
  deleteIndustry(industryId: number): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/industry/${industryId}`
    return httpService.delete(url)
  },
  exportIndustries(params: TableViewParameters): Promise<AxiosResponse<any>> {
    const { pageSize = 10, page = 1, sorts = null, filters = null } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)

    const host = baseUrl()
    const url = `https://${host}/industries/export?${query}`
    return httpService.get(url, { responseType: 'blob' })
  },
}
